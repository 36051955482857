import React from 'react';
import './App.css';

import ListItem from './ListItem.js';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      foods: [
      ],
      // e.g. {name: 'Spaghetti', price: 10.5, id}

      drinks: [
      ],
      // e.g. {name: 'Martini', price: 22.5}

      eatingPeople: 5,
      drinkingPeople: 5,

      totalFoodPrice: 0,
      totalDrinksPrice: 0,
      totalPrice: 0,
      totalPerPersonAll: 0,
      totalPerPersonFood: 0,
      totalPerPersonDrinks: 0,

      newFoodName: '',
      newFoodPrice: undefined,

      newDrinkName: '',
      newDrinkPrice: undefined,

      id_counter: 0,
    };

    this.handleFoodNameInputChange = this.handleFoodNameInputChange.bind(this);
    this.handleFoodPriceInputChange = this.handleFoodPriceInputChange.bind(this);
    this.handleDrinkNameInputChange = this.handleDrinkNameInputChange.bind(this);
    this.handleDrinkPriceInputChange = this.handleDrinkPriceInputChange.bind(this);

    this.handleFoodSubmit = this.handleFoodSubmit.bind(this);
    this.handleDrinkSubmit = this.handleDrinkSubmit.bind(this);
    this.calculatePrices = this.calculatePrices.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    
    this.saveState = this.saveState.bind(this);
    this.loadState = this.loadState.bind(this);


  }

  /* =========== 
   * Input Change Handlers 
   * ===========
   */
  handleFoodNameInputChange(event) { this.setState({newFoodName: event.target.value}); }
  handleFoodPriceInputChange(event) { this.setState({newFoodPrice: parseFloat(event.target.value)}); }
  handleDrinkNameInputChange(event) { this.setState({newDrinkName: event.target.value}); }
  handleDrinkPriceInputChange(event) { this.setState({newDrinkPrice: parseFloat(event.target.value)}); }
  handleEatingPeopleInputChange(event) { this.setState({eatingPeople: parseFloat(event.target.value)}); }
  handleDrinkingPeopleInputChange(event) { this.setState({drinkingPeople: parseFloat(event.target.value)}); }

  handleFoodSubmit(event) {
    event.preventDefault();

    var newFood = { 
      name: this.state.newFoodName,
      price: parseFloat(this.state.newFoodPrice),
      id: this.state.id_counter
    };
    
    this.setState(
      {
        foods: [...this.state.foods, newFood ],
        newFoodName: '',
        newFoodPrice: '',
        id_counter: this.state.id_counter + 1
      },
      () => {this.calculatePrices()}
    );
    
  }

  handleDrinkSubmit(event) {
    event.preventDefault();

    var newDrink = {
      name: this.state.newDrinkName,
      price: parseFloat(this.state.newDrinkPrice),
      id: this.state.id_counter
    };

    this.setState(
      {
        drinks: [...this.state.drinks, newDrink ],
        newDrinkName: '',
        newDrinkPrice: '',
        id_counter: this.state.id_counter + 1
       },
      () => {this.calculatePrices()}
    );
  }

  deleteItem(id, type) {
    if (type === "food" ) {
      this.setState({foods: this.state.foods.filter((food) => { 
            return food.id !== id
          })
        },
        () => {this.calculatePrices()}
      );
    }
    else if (type === "drink" ) {
      this.setState({drinks: this.state.drinks.filter((drink) => { 
            return drink.id !== id 
          })
        },
        () => {this.calculatePrices()}
      );
    }
  }


  /**
   * Calculate all prices if valid state
   */
  calculatePrices() {

    let totalFoodPrice = 0;
    this.state.foods.forEach(food => {
      totalFoodPrice += food.price;
    });

    let totalDrinksPrice = 0;
    this.state.drinks.forEach(drink => {
      totalDrinksPrice += drink.price;
    });

    let totalPrice = totalFoodPrice + totalDrinksPrice;

    let totalPerPersonFood = totalFoodPrice / this.state.eatingPeople;
    let totalPerPersonDrinks = totalDrinksPrice / this.state.drinkingPeople;
    let totalPerPersonAll = totalPerPersonFood + totalPerPersonDrinks;

    this.setState({
      totalFoodPrice: totalFoodPrice,
      totalDrinksPrice: totalDrinksPrice,
      totalPrice: totalPrice,
      totalPerPersonFood:  totalPerPersonFood,
      totalPerPersonDrinks:  totalPerPersonDrinks,
      totalPerPersonAll:  totalPerPersonAll,
    });
  }


  saveState() {
    localStorage.setItem('state', JSON.stringify(this.state));
    this.setState({canLoadState: true});
  }

  loadState() {
    let stateString = localStorage.getItem('state');
    console.log(JSON.parse(stateString));
    let savedState = JSON.parse(stateString);
    this.setState({
      foods: savedState.foods,
      drinks: savedState.drinks,
      eatingPeople: savedState.eatingPeople,
      drinkingPeople: savedState.drinkingPeople,
      id_counter: savedState.id_counter
    },
    () => {this.calculatePrices()})
  }

  render() {
    let canLoadState = this.state.canLoadState;
    let loadDataClass = canLoadState ? "active" : "";

    let {foods, drinks} = this.state;
    let {totalDrinksPrice, totalFoodPrice, totalPrice} = this.state;
    let {totalPerPersonDrinks, totalPerPersonFood, totalPerPersonAll } = this.state;
    
    return (
      <div className="App">
        <header className="App-header">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="none">
            <path fill="#252222" d="M0,96L24,90.7C48,85,96,75,144,80C192,85,240,107,288,122.7C336,139,384,149,432,165.3C480,181,528,203,576,186.7C624,171,672,117,720,117.3C768,117,816,171,864,192C912,213,960,203,1008,192C1056,181,1104,171,1152,192C1200,213,1248,267,1296,272C1344,277,1392,235,1416,213.3L1440,192L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path>
          </svg>
          <div className="logo"></div>
          <p className="container description">
            Easily calculate what everyone has to pay,<br/>
            no matter the situation
          </p>
        </header>

        <div className="container">

          <div className="card">
            <h2 className="section-title">Foods</h2>
            {foods.map(food => {
              return (<ListItem key={food.id} type="food" item={food} deleteItem={this.deleteItem}/>)
            })}
            <form className="add-item-form" onSubmit={this.handleFoodSubmit}>
              <input type="text" placeholder="Name" value={this.state.newFoodName} onChange={this.handleFoodNameInputChange} />
              <input type="number" step="any" placeholder="Price" value={this.state.newFoodPrice} onChange={this.handleFoodPriceInputChange} />
              <input type="submit" value="Add" />
            </form> 
          </div>
          
          <div className="card">
            <h2 className="section-title">Drinks</h2>
            {drinks.map(drink => {
              return (<ListItem key={drink.id} type="drink" item={drink} deleteItem={this.deleteItem}/>)
            })}
            
            <form className="add-item-form" onSubmit={this.handleDrinkSubmit}>
              <input type="text" placeholder="Name" value={this.state.newDrinkName} onChange={this.handleDrinkNameInputChange} />
              <input type="number" step="any" placeholder="Price" value={this.state.newDrinkPrice} onChange={this.handleDrinkPriceInputChange} />
              <input type="submit" value="Add" />
            </form>
          </div>
          
          <div className="card">
            <h2 className="section-title">People</h2>
            <div className="add-people-row">
              <label className="subtitle" htmlFor="add-eating-people">That Eat:</label>
              <div className="number-input-container">
                <div className="plus-minus-button minus" onClick={()=>{this.setState({eatingPeople: this.state.eatingPeople - 1}, () => {this.calculatePrices()})}}></div>
                <input  id="add-eating-people" type="number" step="1" value={this.state.eatingPeople} onChange={this.handleEatingPeopleInputChange} />
                <div className="plus-minus-button plus" onClick={()=>{this.setState({eatingPeople: this.state.eatingPeople + 1}, () => {this.calculatePrices()})}}></div>
              </div>
            </div>
          
            <div className="add-people-row">
              <label className="subtitle" htmlFor="add-drinking-people">That Drink:</label>
              <div className="number-input-container">
                <div className="plus-minus-button minus" onClick={()=>{this.setState({drinkingPeople: this.state.drinkingPeople - 1}, () => {this.calculatePrices()})}}></div>
                <input id="add-drinking-people"  type="number" step="1" value={this.state.drinkingPeople} onChange={this.handleDrinkingPeopleInputChange} />
                <div className="plus-minus-button plus" onClick={()=>{this.setState({drinkingPeople: this.state.drinkingPeople + 1}, () => {this.calculatePrices()})}}></div>
              </div>
            </div>
          </div>

          <div className="card">
            <h2 className="section-title">Total</h2>
            <div id="total-section" className="grid-section">
              <p className="subtitle">Total Food price:</p>
              <p className="price-text">{totalFoodPrice.toFixed(2)}€</p>

              <p className="subtitle">Total Drink price:</p>
              <p className="price-text">{totalDrinksPrice.toFixed(2)}€</p>

              <p className="subtitle">Total Price:</p>
              <p className="price-text">{totalPrice.toFixed(2)}€</p>
            </div>
          </div>

          <div className="card">
            <h2 className="section-title">Per Person</h2>
            <div id="per-people-section" className="grid-section">

              <p className="subtitle">Food only:</p>
              <p className="price-text">{totalPerPersonFood.toFixed(2)}€</p>

              <p className="subtitle">Drinks only:</p>
              <p className="price-text">{totalPerPersonDrinks.toFixed(2)}€</p>

              <p className="subtitle">All:</p>
              <p className="price-text">{totalPerPersonAll.toFixed(2)}€</p>
            </div>
          </div>

          {/* <div className=""> */}
            <div onClick={this.saveState} className="action-button active">Save data</div>
            <div onClick={this.loadState} className={"action-button " + loadDataClass}>Load data</div>
          {/* </div> */}

        </div>
      </div>
    );
  }
}

export default App;
