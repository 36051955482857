import React from 'react';

export default class ListItem extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      name: this.props.item.name,
      price: this.props.item.price,
      id: this.props.item.id,

      type: this.props.type
    }

    this.handleDeleteButton = this.handleDeleteButton.bind(this);
  }

  handleDeleteButton() {
    this.props.deleteItem(this.state.id, this.state.type)
  }

  render() {
    let {type, price, name} = this.state;
    let classStr = "item " + type;

    return (
      <div className={classStr}>
        <p className="name">{name}</p>
        <p className="price">{price}€</p>
        <button className="delete-item" onClick={this.handleDeleteButton}></button>
      </div>
    )
  }
}